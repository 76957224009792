import React from "react";

import { CalendarDate } from "@internationalized/date";
import { today } from "@puzzle/utils";

import { styled, Button, colors, Text, Tooltip } from "@puzzle/ui";
import { pluralize } from "@puzzle/utils";

import { DisplayMoneyColumn, DisplayMoneySubrow, GraphContainer } from "./shared";
import MetricCard, { Blurrable } from "./MetricCard";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import UpgradeButton from "components/featureGate/UpgradeButton";
import { MetricTileDateGate } from "components/featureGate/MetricTileDateGate";
import useMonetization from "components/monetization/useMonetization";
import { RunwayGraph } from "./RunwayGraph";

import Calculating from "./Calculating";
import {
  Download,
  GoogleSheet,
  LinkExternal,
  MeowRounded,
  Sturppy,
  Causal,
  Exclamation,
  DaydreamLogo,
  Runway,
} from "@puzzle/icons";
import { ReportExportModal } from "components/reports/modals/ReportExportModal";
import { DynamicReportType } from "graphql/types";
import Link from "components/common/Link";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { EstimatedTag } from "./EstimatedTag";
import { Box, Stack, vars } from "ve";
import { useToggle } from "react-use";
import { EmptyGraph, EmptyGraphVariant } from "./EmptyGraph";

const Header = styled("div", {
  color: "$gray300",
  textVariant: "$bodyS",
  fontWeight: "$heavy",
});

const Description = styled(Header, {
  color: "$gray400",
  fontWeight: "$normal",
});

const Container = styled("div", {
  display: "grid",
  gridAutoFlow: "row",
  gridGap: "$2h",
  padding: "0 0 $2 0",
});

const OptionBox = styled("div", {
  backgroundColor: "#3C3B4F",
  display: "grid",
  gridTemplateColumns: "min-content 1fr",
  padding: "$2",
  borderRadius: "$1",
  gap: "$1h",
  marginBottom: 1,

  "&:hover": {
    backgroundColor: "$rhino750",
  },
});
const OptionFlex = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const MonthContainer = styled("div", {
  height: '100%',
  display: 'flex',
  gap: "$1"
});

interface OptionLinkProps {
  icon: React.ReactNode;
  title: string;
  urlLabel: string;
  urlHref: string;
  featureFlag?: FeatureFlag;
}

const OptionLink = ({ icon, title, urlLabel, urlHref, featureFlag }: OptionLinkProps) => {
  if (featureFlag && !isPosthogFeatureFlagEnabled(featureFlag)) return null;
  return (
    <Link rel="noreferrer noopener" href={urlHref} target="_blank">
      <OptionBox>
        <div>{icon}</div>
        <OptionFlex>
          <Text css={{ marginTop: 2 }} variant="headingM" color="$gray100">
            {title}
          </Text>
          <Text css={{ display: "flex", justifyItems: "center" }} variant="body" color="$gray200">
            {urlLabel}
            <LinkExternal
              css={{ marginLeft: "$0h", marginTop: 2 }}
              color={colors.gray300}
              width={10.5}
            />
          </Text>
        </OptionFlex>
      </OptionBox>
    </Link>
  );
};

const RUNWAY_OPTIONS: OptionLinkProps[] = [
  {
    icon: <Causal />,
    title: "Causal",
    urlLabel: "causal.app",
    urlHref: "https://go.causal.app/puzzle",
  },
  {
    icon: <Runway width={24} height={24} />,
    title: "Runway",
    urlLabel: "runway.com",
    urlHref: "https://runway.com/partners/puzzle/",
  },
  {
    icon: <Sturppy />,
    title: "Sturppy",
    urlLabel: "sturppy.com",
    urlHref: "https://www.sturppy.com/integrations/puzzle",
  },
  {
    icon: <MeowRounded />,
    title: "Meow",
    urlLabel: "meow.co",
    urlHref: "https://app.meow.co/runway?utm_source=puzzle",
  },
  {
    icon: <DaydreamLogo width={24} height={24} />,
    title: "Daydream",
    urlLabel: "daydream.co",
    urlHref: "https://daydream.co/templates/founder-finances-by-puzzle",
  },
];

const MODELS_OPTIONS: OptionLinkProps[] = [
  {
    icon: <GoogleSheet />,
    title: "Free forecast model",
    urlLabel: "Use template",
    urlHref:
      "https://docs.google.com/spreadsheets/d/147IBpva8Dyrx8lVcgpGv1sfGEI-4HRHT5M7Dg9XNFe8/copy",
  },
  {
    icon: <GoogleSheet />,
    title: "Free budgeting model",
    urlLabel: "Use template",
    urlHref:
      "https://docs.google.com/spreadsheets/d/1NImoIMBBKCRuNf-qaDspp_Fw6xaqTJJvIw9XA8OYqy0/copy",
  },
];

interface EstimatedRunwayProps {
  runway?: number;
  cashOutDate?: CalendarDate;
  todaysCash?: string;
  loading: boolean;
  ingesting?: boolean;
  isGraphFeatureGated?: boolean;
}

const EstimatedRunway = ({
  runway,
  cashOutDate,
  todaysCash,
  loading,
  ingesting,
  isGraphFeatureGated,
}: EstimatedRunwayProps) => {
  const [blur, toggleBlur] = useToggle(false);
  const { initialIngestCompleted, company } = useActiveCompany<true>();
  const monthFormatter = useCompanyDateFormatter({ month: "short", year: "numeric" });
  const [showExportModal, setShowExportModal] = React.useState(false);
  const { isModalVisible: isUpgradeModalVisible } = useMonetization();

  const showGraph = !!company?.features.metricCardsCore;

  return (
    <>
      <ReportExportModal
        open={showExportModal}
        onConfirm={() => setShowExportModal(false)}
        onCancel={() => setShowExportModal(false)}
        title="Cash Activity"
        type={DynamicReportType.CashActivityReport}
      />
      <MetricCard
        cardId="runway"
        loading={!initialIngestCompleted ? false : loading}
        toggleBlur={toggleBlur}
        blur={blur}
        blurEnabled={true}
        shouldHandleBlur={false}
        header="Runway Run Rate"
        hasGraph={showGraph}
        isFeatureGated={isGraphFeatureGated}
        expandButtonOnTop={true}
        expandable={!isUpgradeModalVisible}
        expandedContent={
          <Container css={{ overflowY: "scroll" }}>
            <div>
              <Description
                css={{ marginBottom: runway === undefined || runway < 12 ? "$1h" : "$2h" }}
              >
                This is the last month your current cash balance will be able to fully cover your
                monthly burn rate.
              </Description>

              {runway === undefined ? (
                <Description css={{ marginBottom: "$2h" }}>
                  If you are generating cash rather than burning cash, your runway will display as
                  infinite (Wow! Keep it up!).
                </Description>
              ) : (
                runway < 12 && (
                  <Description css={{ marginBottom: "$2h" }}>
                    {`Your runway of ${pluralize(
                      runway,
                      "month"
                    )} suggests that you may want to start planning for your next fundraise — as a rule of thumb, at least six months in advance.`}
                  </Description>
                )
              )}
              <Header css={{ marginBottom: "$2" }}>Integrated with Puzzle</Header>
              {RUNWAY_OPTIONS.map(({ icon, title, urlLabel, urlHref, featureFlag }) => (
                <OptionLink
                  key={urlHref}
                  icon={icon}
                  title={title}
                  urlLabel={urlLabel}
                  urlHref={urlHref}
                  featureFlag={featureFlag}
                />
              ))}
            </div>

            <div>
              <Header css={{ marginBottom: "$0h" }}>Build your own models</Header>
              <Button
                css={{ marginBottom: "$0h" }}
                onClick={() => setShowExportModal(true)}
                variant="secondary"
                fullWidth
              >
                Export financial statements <Download size={13} />
              </Button>
            </div>
            <div>
              <Header css={{ marginBottom: "$2" }}>Use free templates from Puzzle</Header>

              {MODELS_OPTIONS.map(({ icon, title, urlLabel, urlHref, featureFlag }) => (
                <OptionLink
                  key={urlHref}
                  icon={icon}
                  title={title}
                  urlLabel={urlLabel}
                  urlHref={urlHref}
                  featureFlag={featureFlag}
                />
              ))}
            </div>
          </Container>
        }
      >
        <MetricTileDateGate>
          <DisplayMoneyColumn>
            <DisplayMoneySubrow>
              {!initialIngestCompleted ? (
                <Calculating />
              ) : runway === undefined ? (
                <Blurrable active={blur}>Infinite</Blurrable>
              ) : (
                <Blurrable active={blur}>
                  <MonthContainer>
                    {cashOutDate ? monthFormatter.format(cashOutDate).replace(" 20", " ‘") : ""}{" "}
                    <small>({pluralize(runway, "month")})</small>
                  </MonthContainer>
                </Blurrable>
              )}
              {initialIngestCompleted && ingesting && (
                <Tooltip content="Looks like were are still ingesting data from one or more accounts.">
                  <Box css={{ display: "flex", alignItems: "center" }}>
                    <Exclamation css={{ opacity: 0.5 }} />
                  </Box>
                </Tooltip>
              )}
              <EstimatedTag
                modalTitle="Estimated Runway Rate"
                modalBody={
                  <Stack css={{ gap: vars.space["$2"] }}>
                    <Text>Your Runway is currently an estimated value.</Text>
                    <Text>
                      Navigate to the transactions page to see what you can do to increase the
                      accuracy of this metric.
                    </Text>
                  </Stack>
                }
              />
            </DisplayMoneySubrow>
          </DisplayMoneyColumn>

          {showGraph && (
            <>
              <GraphContainer gateBlur={isGraphFeatureGated}>
              {(ingesting || loading)  && <EmptyGraph variant={EmptyGraphVariant.SOLID} />}
              {!ingesting && !loading && <RunwayGraph
                todayCash={todaysCash}
                startDate={today("UTC").toString()}
                endDate={cashOutDate?.toString()}
              />}
                
              </GraphContainer>
              {isGraphFeatureGated && (
                <UpgradeButton
                  title="Upgrade to view"
                  variant="outline"
                  css={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              )}
            </>
          )}
        </MetricTileDateGate>
      </MetricCard>
    </>
  );
};

export default EstimatedRunway;
